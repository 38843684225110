import { mapActions } from 'vuex';
import { getToken } from '@/helpers/jwt.service';
export default {
  name: 'ApplicationPdfMixin',
  data() {
    return {
      pdfModal: false,
      fullPdfModal: false,
      checkPdfTimeout: '',
      checkFullPdfTimeout: '',
    };
  },
  beforeDestroy() {
    if (this.checkPdfTimeout) clearTimeout(this.checkPdfTimeout);

    if (this.checkFullPdfTimeout) clearTimeout(this.checkFullPdfTimeout);
  },
  methods: {
    ...mapActions({
      getPdf: 'applications/getPdf',
      generatePdf: 'applications/generatePdf',
      checkPdf: 'applications/checkPdf',

      getFullPdf: 'applications/getFullPdf',
      generateFullPdf: 'applications/generateFullPdf',
      checkFullPdf: 'applications/checkFullPdf',
    }),
    startPdfChecking(application) {
      this.checkPdf(application.id).then((result) => {
        if (result.ready) {
          this.pdfModal = true;
        } else {
          this.checkPdfTimeout = setTimeout(() => {
            this.startPdfChecking(application);
          }, 60000);
        }
      });
    },

    startFullPdfChecking(application) {
      this.checkFullPdf(application.id).then((result) => {
        if (result.ready) {
          this.fullPdfModal = true;
        } else {
          this.checkFullPdfTimeout = setTimeout(() => {
            this.startFullPdfChecking(application);
          }, 60000);
        }
      });
    },

    async downloadApplication(applicaiton) {
      if (!applicaiton.pdf_without_docs_ready && applicaiton.pdf_without_docs_in_progress) {
        return;
      }

      this.getPdf(applicaiton.id)
        .then((result) => {
          window.open(result.link, '_blank');
        })
        .catch((err) => {
          console.error('err :>> ', err);
        });

      // document.body.click();
      // const token = getToken();
      // if (!token) return '';
      // const url =
      //   process.env.VUE_APP_API_BASE_URL +
      //   '/applications/' +
      //   applicaiton.id +
      //   '/download_pdf_without_docs?Authorization=Token ' +
      //   token;

      // const a = document.createElement('a');
      // a.href = url;
      // // a.target = '_blank';
      // a.click();
      // this.pdfModal = false;
    },

    async downloadFullApplication(applicaiton) {
      if (!applicaiton.pdf_with_docs_ready && applicaiton.pdf_with_docs_in_progress) {
        return;
      }

      this.getFullPdf(applicaiton.id)
        .then((result) => {
          window.open(result.link, '_blank');
        })
        .catch((err) => {
          console.error('err :>> ', err);
        });

      // document.body.click();
      // const token = getToken();
      // if (!token) return '';
      // const url =
      //   process.env.VUE_APP_API_BASE_URL +
      //   '/applications/' +
      //   applicaiton.id +
      //   '/download_pdf_with_docs?Authorization=Token ' +
      //   token;

      // const a = document.createElement('a');
      // a.href = url;
      // // a.target = '_blank';
      // a.click();
      // this.fullPdfModal = true;
    },
  },
};
