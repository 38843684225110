<template>
  <div>
    <b-row v-if="!isApplicant" class="mb-4">
      <b-col :cols="12" md="12" class="ml-auto text-right">
        <div class="my-lg-0 my-5 d-flex align-items-center justify-content-between">
          <div
            v-if="get(applicationData, 'days_to_left') <= 0"
            class="alert bg-light-danger d-flex align-items-center justify-content-between  px-5 mb-2"
          >
            <!--begin::Wrapper-->
            <div class="d-flex flex-column pe-0 pe-sm-10">
              <!--begin::Content-->
              <span>
                The application
                {{
                  get(applicationData, 'days_to_left') == 0
                    ? 'is expred'
                    : get(applicationData, 'days_to_left') > 0
                    ? 'will be expred in ' + get(applicationData, 'days_to_left')
                    : 'was expred ' + Math.abs(get(applicationData, 'days_to_left')) + ' days ago'
                }}
              </span>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>
          <div class="ml-auto d-inline-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <span class="mr-5">Status</span>
              <div
                class="text-center"
                v-if="
                  get(applicationData, 'days_to_left', null) != null &&
                    Number(get(applicationData, 'days_to_left', null)) <= 0 &&
                    get(applicationData, 'rejected')
                "
              >
                <button
                  :id="`application_${applicationData.id}`"
                  type="button"
                  class="btn btn-light-danger white-space-nowrap font-weight-bold"
                >
                  {{ autoRejectStatusText }}
                </button>
              </div>
              <div v-else class="text-center">
                <button
                  :id="`application_${applicationData.id}`"
                  type="button"
                  class="btn white-space-nowrap font-weight-bold"
                  :class="[`btn-light-${getBy(statuses, 'text', applicationData.status).color}`]"
                >
                  {{ get(applicationData, 'status') }}
                </button>

                <template v-if="isInterview(get(applicationData, 'status'))">
                  <p class="mt-1 mb-0">
                    {{ get(applicationData, 'last_interview_schedule.data.attributes.date') }}
                    {{ get(applicationData, 'last_interview_schedule.data.attributes.time') | moment('hh:mm A') }}
                  </p>
                </template>
              </div>

              <b-tooltip
                v-if="isReason(get(applicationData, 'status')) && get(applicationData, 'reason')"
                :target="`application_${applicationData.id}`"
                placement="top"
              >
                {{ get(applicationData, 'reason') }}
              </b-tooltip>
            </div>
            <!-- v-if="getActions(applicationData, false).length > 0" -->
            <div class="ml-4">
              <b-dropdown class="btn-icon" menu-class="w-max-content w-200px" variant="clean" right no-caret>
                <template v-slot:button-content>
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary dropdown-toggle">Actions</button>
                  </div>
                </template>
                <ul class="navi navi-hover py-5">
                  <template v-for="(action, index) in getActions(applicationData, false)">
                    <li class="navi-item" :key="index + '__actions'">
                      <a class="navi-link" href="javascript:;" @click="handleActionClick(action, applicationData)">
                        <span class="navi-icon">
                          <i class="font-size-h1 mr-2" :class="action.icon"></i>
                        </span>
                        <span class="navi-text">{{ action.text }}</span>
                      </a>
                    </li>
                  </template>

                  <div v-if="getActions(applicationData, false).length > 0" class="dropdown-divider"></div>

                  <template v-for="(victigUrl, index) in applicantVictigFiles">
                    <li class="navi-item" :key="index">
                      <a class="navi-link" :href="victigUrl" target="_blank">
                        <span class="navi-icon mr-2">
                          <img style="width:25px" src="media/icons/pdf.png" />
                        </span>
                        <span class="navi-text">Download Apl {{ index + 1 }} Cr Report</span>
                      </a>
                    </li>
                  </template>

                  <li class="navi-item" @click="downloadApplication(applicationData)">
                    <a class="navi-link" href="javascript:;">
                      <span class="navi-icon mr-2">
                        <img style="width:25px" src="media/icons/pdf.png" />
                      </span>
                      <div
                        v-if="!applicationData.pdf_without_docs_ready && applicationData.pdf_without_docs_in_progress"
                        class="d-flex align-items-center justify-content-between"
                      >
                        Processing
                        <div class="ml-2 spinner spinner-sm spinner-primary"></div>
                      </div>
                      <div v-else class="d-flex flex-column align-items-start">
                        <span class="navi-text">Download Application</span>
                        <div
                          v-if="applicationData.pdf_without_docs_generated_at"
                          class="label label-light-primary label-inline font-weight-bold"
                        >
                          {{ applicationData.pdf_without_docs_generated_at | moment('MM/DD/YYYY hh:mm A') }}
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="navi-item" @click="downloadFullApplication(applicationData)">
                    <a class="navi-link" href="javascript:;">
                      <span class="navi-icon mr-2">
                        <img style="width:25px" src="media/icons/pdf.png" />
                      </span>
                      <div
                        v-if="!applicationData.pdf_with_docs_ready && applicationData.pdf_with_docs_in_progress"
                        class="d-flex align-items-center justify-content-between"
                      >
                        Processing
                        <div class="ml-2 spinner spinner-sm spinner-primary"></div>
                      </div>
                      <div v-else class="d-flex flex-column align-items-start">
                        <span class="navi-text">Download Full Application</span>
                        <div
                          v-if="applicationData.pdf_with_docs_generated_at"
                          class="label label-light-primary label-inline font-weight-bold"
                        >
                          {{ applicationData.pdf_with_docs_generated_at | moment('MM/DD/YYYY hh:mm A') }}
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isApplicant" class="mb-4">
      <b-col :cols="12" md="12" class="ml-auto text-right">
        <div class="my-lg-0 my-5 d-flex align-items-center justify-content-between">
          <div class="ml-auto d-inline-flex align-items-center justify-content-between">
            <!-- v-if="getActions(applicationData, false).length > 0" -->
            <div class="ml-4">
              <b-btn
                v-if="showBtn(applicationData)"
                :to="{ name: 'applications-create' }"
                variant="warning"
                class="font-weight-bold py-2 px-6"
              >
                {{ isReApply ? 'Reapply' : 'Edit' }} Application
              </b-btn>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card class="card-custom rounded-top-0">
      <template v-if="loading">
        <div class="position-relative" style="height:400px;">
          <spinner bg-transparent />
        </div>
      </template>
      <b-row v-else>
        <b-col lg="3">
          <b-nav
            vertical
            class="nav-pills-custom position-sticky"
            :style="{ top: '130px' }"
            v-scroll-spy-active
            v-scroll-spy-link="{ selector: 'a.nav-link' }"
          >
            <b-nav-item href="javascript:;">Personal Information</b-nav-item>
            <template v-for="(item, key) in headers.sections">
              <b-nav-item v-if="hasAnyField(key)" :key="key" href="javascript:;">{{ item.name }}</b-nav-item>
            </template>
            <b-nav-item href="javascript:;">Financial Information</b-nav-item>
            <b-nav-item href="javascript:;">Transactional Information</b-nav-item>
            <b-nav-item href="javascript:;">Documents</b-nav-item>
          </b-nav>
        </b-col>
        <b-col v-if="applicationData" lg="9">
          <div
            class="kt-wizard-inside__content border-top border-top-lg-0 border-bottom-0 border-lg-left pb-0 mb-0 mt-7 mt-lg-0"
          >
            <b-row>
              <b-col>
                <div v-scroll-spy="{ offset: 130 }" class="pt-7 pt-lg-0 pl-lg-9">
                  <div id="personalInformation">
                    <div class="table-responsive">
                      <h3 class="text-primary mb-0">Personal Information</h3>

                      <table
                        v-for="(applicant, $index) in applicantsList"
                        :key="$index"
                        class="table table-borderless text-reset mb-0"
                      >
                        <tbody>
                          <tr>
                            <td class="pl-0 pr-0" colspan="2">
                              <h4 class="text-primary mb-0">Applicant {{ $index + 1 }}</h4>
                            </td>
                          </tr>

                          <tr v-for="header in headers.personal" :key="`${$index}_${header.name}`">
                            <th class="pl-0">{{ header.name }}</th>
                            <td class="pr-0" style="width: 70%;">
                              {{ get(applicant, header.key, 'N/A', '', '', header.formatter) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="separator separator-solid my-10" />
                  </div>
                  <template v-for="(item, key) in headers.sections">
                    <div v-if="hasAnyField(key)" :id="item.id" :key="key">
                      <div class="table-responsive">
                        <h3 class="text-primary mb-0">{{ item.name }}</h3>

                        <table
                          v-for="(applicant, $index) in applicantsList"
                          :key="$index"
                          class="table table-borderless text-reset mb-0"
                        >
                          <tbody v-if="hasAnyField(key, applicant)">
                            <tr>
                              <td class="pl-0 pr-0" colspan="2">
                                <h4 class="text-primary mb-0">Applicant {{ $index + 1 }}</h4>
                              </td>
                            </tr>
                            <template v-for="header in item.data">
                              <template
                                v-if="get(applicant, header.key + '.' + header.checkField) || !header.checkField"
                              >
                                <tr v-if="header.name" :key="`${$index}_${header.name}`">
                                  <th class="pl-0 pb-0">
                                    <h5 class="ml-2 mb-0 text-primary">{{ header.name }}</h5>
                                  </th>
                                </tr>
                                <template v-for="(field, fIndex) in header.fields">
                                  <tr
                                    v-if="field.visible == undefined || field.visible(get(applicant, header.key, {}))"
                                    :key="`${$index}_${fIndex}_${header.key}_${field.name}_sub_fields`"
                                  >
                                    <th :class="header.name ? 'pl-2' : 'pl-0'">{{ field.name }}</th>
                                    <td class="pr-0" style="width: 70%;">
                                      {{
                                        get(get(applicant, header.key, {}), field.key, 'N/A', '', '', field.formatter)
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div class="separator separator-solid my-10" />
                    </div>
                  </template>

                  <div id="financialInformation">
                    <div class="table-responsive">
                      <table class="table table-borderless text-reset mb-0">
                        <tbody>
                          <template v-for="(item, key, index) in headers.generalHeaders">
                            <template v-if="key == 'divider'">
                              <tr :key="`${key}_divider`">
                                <td colspan="2">
                                  <div class="separator separator-solid my-10" />
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr :key="`${index}_key${key}_title`">
                                <td class="pl-0 pr-0" colspan="2">
                                  <h3 class="text-primary mb-0">{{ item.name }}</h3>
                                </td>
                              </tr>
                              <template v-for="(subItem, subKey) in item.data">
                                <tr v-if="subItem.name" :key="`${index}_key${key}_${subItem}_${subKey}_data`">
                                  <td class="pl-2 pr-0 pb-0" colspan="2">
                                    <h4 class="text-primary mb-0">{{ subItem.name }}</h4>
                                  </td>
                                </tr>
                                <template v-if="$data[key][subKey].length < 1">
                                  <tr :key="`${index}_${subKey}`">
                                    <th>N/A</th>
                                  </tr>
                                </template>
                                <template v-else>
                                  <template v-for="(dataItem, dataItemIndex) in $data[key][subKey]">
                                    <template v-for="(field, fIndex) in subItem.fields">
                                      <tr
                                        v-if="field.visible == undefined || field.visible(dataItem)"
                                        :key="`${field.key}_${index}_${subKey}_${dataItemIndex}_${fIndex}`"
                                      >
                                        <th class="pl-2" :key="field.name">{{ field.name }}</th>
                                        <td class="pr-0" style="width: 70%;">
                                          <template v-if="field.owner">
                                            <template v-if="get(dataItem, field.key)">
                                              Applicant {{ getApplicantWithIndex(get(dataItem, field.key)).index }}
                                            </template>
                                            <template v-else>Joint</template>
                                          </template>
                                          <template v-else>
                                            {{
                                              field.key == 'index'
                                                ? dataItemIndex + 1
                                                : field.balance
                                                ? moneyDollarForamter(get(dataItem, field.key))
                                                : get(dataItem, field.key, 'N/A', '', '', field.formatter) || 'N/A'
                                            }}
                                          </template>
                                        </td>
                                      </tr>
                                    </template>
                                    <tr :key="`${index}_${subKey}_${dataItemIndex}_divider`">
                                      <td colspan="2">
                                        <hr v-if="false" />
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="separator separator-solid my-10" />
                  </div>

                  <div id="transactionalInformation">
                    <div class="table-responsive">
                      <table class="table table-borderless text-reset mb-0">
                        <tbody>
                          <template v-for="(field, index) in headers.applications">
                            <tr :key="index + '_title'">
                              <td class="pl-0 pr-0" colspan="2">
                                <h3 class="text-primary mb-0">{{ field.name }}</h3>
                              </td>
                            </tr>
                            <template v-for="header in field.fields">
                              <tr
                                v-if="field.visible == undefined || field.visible(applicationData)"
                                :key="`${index}_${header.name}`"
                              >
                                <th class="pl-0">{{ header.name }}</th>
                                <td class="pr-0" style="width: 70%;">
                                  {{
                                    header.balance
                                      ? moneyDollarForamter(get(applicationData, header.key))
                                      : get(applicationData, header.key, 'N/A', '', '', header.formatter)
                                  }}
                                </td>
                              </tr>
                            </template>
                          </template>
                          <template v-for="(field, key, index) in headers.contacts">
                            <tr :key="`${key}_${index}_title`">
                              <td class="pl-0 pr-0" colspan="2">
                                <h3 class="text-primary mb-0">{{ field.name }}</h3>
                              </td>
                            </tr>
                            <template v-for="header in field.fields">
                              <tr
                                v-if="field.visible == undefined || field.visible(contacts[key])"
                                :key="`${field}_${index}_${header.name}`"
                              >
                                <th class="pl-0">{{ header.name }}</th>
                                <td class="pr-0" style="width: 70%;">
                                  {{ get(contacts[key], header.key, 'N/A', '', '', header.formatter) }}
                                </td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="separator separator-solid my-10" />
                  </div>

                  <div id="documents">
                    <div class="table-responsive">
                      <h3 class="text-primary mb-0">Documents</h3>

                      <table class="table table-borderless text-reset mb-0">
                        <tbody>
                          <tr>
                            <td class="pl-0 pr-0" colspan="2">
                              <h4 class="text-primary mb-0">{{ headers.docs.general.name }}</h4>
                            </td>
                          </tr>

                          <tr v-for="header in headers.docs.general.fields" :key="`${header.name}`">
                            <th class="pl-0">{{ header.name }}</th>
                            <td class="pr-0" style="width: 70%;">
                              <a
                                v-if="get(applicationData, header.key)"
                                :href="get(applicationData, header.key)"
                                class="mr-5"
                                target="_blank"
                              >
                                <i class="flaticon2-download"></i>
                              </a>
                              <span v-else>N/A</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table v-if="supplementals.length" class="table table-borderless text-reset mb-0">
                        <tbody>
                          <tr>
                            <td class="pl-0 pr-0" colspan="2">
                              <h4 class="text-primary mb-0">Supplementary documents</h4>
                            </td>
                          </tr>

                          <tr v-for="(doc, index) in supplementalDocs" :key="`${index}_supp`">
                            <th class="pl-0">{{ doc.name }}</th>
                            <td class="pr-0" style="width: 70%;">
                              <a v-if="doc.file" :href="doc.file" class="mr-5" target="_blank">
                                <i class="flaticon2-download"></i>
                              </a>
                              <span v-else>N/A</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        v-for="(applicant, $index) in applicantsList"
                        :key="$index"
                        class="table table-borderless text-reset mb-0"
                      >
                        <tbody>
                          <tr>
                            <td class="pl-0 pr-0" colspan="2">
                              <h4 class="text-primary mb-0">Applicant {{ $index + 1 }}</h4>
                            </td>
                          </tr>

                          <tr v-for="header in headers.docs.applicants.fields" :key="`${$index}_${header.name}`">
                            <th class="pl-0">{{ header.name }}</th>
                            <td class="pr-0" style="width: 70%;">
                              <a
                                v-if="
                                  Array.isArray(get(applicant, header.key))
                                    ? get(applicant, header.key, []).length > 0
                                    : get(applicant, header.key)
                                "
                                :href="get(applicant, header.key)"
                                class="mr-5"
                                target="_blank"
                              >
                                <i class="flaticon2-download"></i>
                              </a>
                              <span v-else>N/A</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-modal v-model="interview_modal" footer-class="text-right">
        <template v-slot:modal-title>
          Date and Time
        </template>
        <template v-slot:modal-footer>
          <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleInterviewSubmit">
            Schedule
          </b-btn>
        </template>
        <VueCtkDateTimePicker
          v-model="interview_form.date"
          label="Select Date & Time"
          :class="{ 'has-error': validateState($v.interview_form.date) === false }"
        />

        <b-form-invalid-feedback v-if="validateState($v.interview_form.date) === false" class="d-block">
          This field is required
        </b-form-invalid-feedback>
      </b-modal>

      <!-- <b-modal v-model="reason_modal" centered footer-class="text-right">
        <template v-slot:modal-title>
          {{ reason_form.status }}
        </template>

        <template v-slot:modal-footer>
          <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="onReasonSubmit()">
            Submit
          </b-btn>
        </template>

        <b-form-group description="Please write extended description to this action">
          <template v-slot:label>
            Description
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea type="text" v-model="reason_form.reason" :state="validateState($v.reason_form.reason)" />
        </b-form-group>
      </b-modal> -->
      <b-modal v-model="reason_modal" centered footer-class="text-right">
        <template v-slot:modal-title>
          {{ isApplicationAutoRejected ? autoRejectText : reason_form.status }}
        </template>

        <template v-slot:modal-footer>
          <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="onReasonSubmit()">
            Submit
          </b-btn>
        </template>

        <b-form-group
          v-if="!isApplicationRejected && !isApplicationAutoRejected"
          description="Please write extended description to this action"
        >
          <template v-slot:label>
            Description
            <!-- <span class="text-danger">*</span> -->
          </template>
          <b-form-textarea type="text" v-model="reason_form.reason" :state="validateState($v.reason_form.reason)" />
        </b-form-group>

        <b-form-group v-if="isApplicationRejected || isApplicationAutoRejected">
          <template v-slot:label>
            File
            <span class="text-danger">*</span>
          </template>
          <b-btn variant="light-primary" class="font-weight-bolder" @click="focusFileInput(`file_upload`)">
            <i class="fas fa-file"></i>
            Upload file
          </b-btn>
          <input
            type="file"
            style="display: none;"
            :ref="`file_upload`"
            accept="application/pdf"
            @change="handleFileUploaded"
          />
          <div v-if="file_url" class="mt-2 d-flex align-items-center">
            <span class="form-text  mr-5">
              Uploaded file
            </span>
            <a :href="file_url" class="mr-5" target="_blank">
              <i class="flaticon2-download"></i>
            </a>
          </div>
          <b-form-invalid-feedback
            style="display:block"
            v-if="validateState($v.reason_form.rejection_document) == false"
          >
            File is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import applicationActions from '@/mixins/application-actions';
import applicationPdf from '@/mixins/application-pdf';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'applications-single-details',
  mixins: [applicationActions, applicationPdf],
  validations() {
    return {
      interview_form: {
        application_id: { required },
        date: { required },
      },
      reason_form: {
        id: { required },
        status: { required: this.isApplicationAutoRejected ? false : required },
        reason: {},
        rejection_document: {
          required: this.isApplicationRejected || this.isApplicationAutoRejected ? required : false,
        },
      },
    };
  },

  data: () => ({
    loading: false,
    // applicationData: null,
    applicantData: {},
    misc: ['militaries', 'crimes', 'evicteds'],
    applicantsList: [],
    assets: {
      cashes: [],
      certificate_deposites: [],
      securities: [],
      real_estates: [],
      vehicles: [],
      individual_retirement_accounts: [],
      life_insurances: [],
      businesses: [],
      properties: [],
    },
    liabilities: {
      installment_debts: [],
      credit_card_debts: [],
      mortgages: [],
      automobiles: [],
      other_liabilities: [],
    },
    annual_income: {
      data: [],
    },
    expenses: {
      vehicle_leases: [],
      personal_loans: [],
      student_loans: [],
      mortgage_expenses: [],
      alimonies: [],
      child_supports: [],
      other_expenses: [],
    },
    others: {
      outstanding_judgments: [],
      personal_bankruptcies: [],
      business_bankruptcies: [],
      property_forcloseds: [],
    },
    general: {
      alterations: [],
      instruments: [],
      subtenants: [],
      occupants: [],
      pets: [],
    },
    contacts: {
      sellerData: {},
      sellerBrokerData: {},
      applicantBrokerData: {},
      attorneyData: {},
      purchaseData: {},
    },

    interview_modal: false,
    interview_form: {
      application_id: '',
      date: '',
    },

    reason_modal: false,
    reason_form: {
      id: '',
      status: '',
      reason: '',
      rejection_document: '',
    },
    file_url: '',
    supplementalDocs: [],
    submiting: false,
  }),
  computed: {
    ...mapGetters({
      applicationData: 'applications/item',
      contactsList: 'contacts/items',
      supplementals: 'supplemental/items',
      applicantSupplementals: 'applicantSupplemental/items',
    }),
    isReApply() {
      return (
        this.applicationData.days_to_left != null &&
        this.applicationData.days_to_left <= 0 &&
        this.applicationData.rejected
      );
    },
    applicantVictigFiles() {
      const data = [];
      for (const applicant of this.get(this.applicationData, 'applicants.data', [])) {
        if (applicant.attributes.victig_pdf_file) data.push(applicant.attributes.victig_pdf_file);
      }
      return data;
    },
    headers() {
      const contactFileds = [
        { name: 'Company Name', key: 'company_name' },
        { name: 'First Name', key: 'first_name' },
        { name: 'Last Name', key: 'last_name' },
        { name: 'Email Address', key: 'email' },
        { name: 'Home Phone Number', key: 'home_phone' },
        { name: 'Cell Phone Number', key: 'phone' },
        { name: 'Street address', key: 'street_address' },
        { name: 'Apt/Suite', key: 'suite' },
        { name: 'City', key: 'city' },
        { name: 'State', key: 'state' },
        { name: 'Zip', key: 'zip' },
      ];
      const liabilitiesFileds = [
        { name: 'Account Owner', key: 'attributes.admin_user.data.id', owner: true },
        { name: 'Principal Balance Remaining', key: 'attributes.balance', balance: true },
        { name: 'Monthly Payment', key: 'attributes.monthly_payments', balance: true },
      ];
      const expensesFields = [
        { name: 'Assigned to', key: 'attributes.admin_user.data.id', owner: true },
        { name: 'Amount', key: 'attributes.amount', balance: true },
      ];
      return {
        applications: [
          {
            name: 'Apartment',
            fields: [
              { name: 'Apartment Number', key: 'appartment_number' },
              { name: 'Number Of Shares Of Stock', key: 'number_of_shares_of_stock' },
              { name: 'Street Address', key: 'street_address' },
              { name: 'Apt/Suite', key: 'suite' },
              { name: 'City', key: 'city' },
              { name: 'State', key: 'state' },
              { name: 'ZIP', key: 'zip' },
            ],
          },
          {
            name: 'Purchase Price',
            fields: [
              { name: 'Purchase Price of the Cooperative', key: 'purchase_price', balance: true },
              { name: 'Down Payment', key: 'down_payment', balance: true },
              { name: 'Name of Source of down Payment', key: 'name_of_source' },
              { name: 'Monthly maintenance', key: 'monthly_maintenance', balance: true },
              { name: 'Loan to Purchase the Cooperative', key: 'require', formatter: (v) => this.getBooleanInfo(v) },
              {
                name: 'Amount of Loan',
                key: 'purchase_price_loan_amount',
                visible: (v) => Boolean(this.get(v, 'require')),
                balance: true,
              },
              {
                name: 'Interest Rate',
                key: 'purchase_price_interest_rate',
                visible: (v) => Boolean(this.get(v, 'require')),
              },
              { name: 'Term in Year(s)', key: 'purchase_price_term', visible: (v) => Boolean(this.get(v, 'require')) },
              {
                name: 'Monthly Loan Payment',
                key: 'purchase_price_monthly_payment',
                visible: (v) => Boolean(this.get(v, 'require')),
                balance: true,
              },
            ],
          },
        ],
        contacts: {
          sellerData: {
            name: 'Seller',
            fields: contactFileds.filter((cf) => cf.key != 'company_name'),
          },
          sellerBrokerData: {
            name: "Seller's Broker",
            fields: contactFileds.filter((cf) => cf.key != 'company_name'),
          },
          applicantBrokerData: {
            name: "Applicant's Broker",
            fields: contactFileds.filter((cf) => cf.key != 'company_name'),
          },
          attorneyData: {
            name: 'Seller Attorney',
            fields: contactFileds,
          },
          purchaseData: {
            name: 'Purchase Attorney',
            fields: contactFileds,
          },
        },
        personal: [
          { name: 'First Name', key: 'attributes.first_name' },
          { name: 'Last Name', key: 'attributes.last_name' },
          { name: 'Email', key: 'attributes.email' },
          { name: 'Address', key: 'attributes.street_address' },
          { name: 'Apt Suite', key: 'attributes.suite' },
          { name: 'City', key: 'attributes.city' },
          { name: 'State', key: 'attributes.state' },
          { name: 'ZIP', key: 'attributes.zip' },
          { name: 'Telephone', key: 'attributes.home_phone_number' },
          { name: 'Cell Phone', key: 'attributes.phone' },
          { name: 'Date of Birth', key: 'attributes.birth_date' },
          { name: 'Drivers License Number', key: 'attributes.drivers_license_number' },
          { name: 'Drivers License State', key: 'attributes.drivers_license_state' },
          { name: 'SSN', key: 'attributes.social_security_number' },
          { name: 'Evictions Lawsuits', key: 'attributes.evicteds', formatter: (v) => this.getArrBooleanInfo(v) },
          { name: 'Criminal Convictions', key: 'attributes.crimes', formatter: (v) => this.getArrBooleanInfo(v) },
          { name: 'Is Diplomatic', key: 'attributes.diplomat', formatter: (v) => this.getBooleanInfo(v) },
          { name: 'Is Legal Resident', key: 'attributes.legal_resident', formatter: (v) => this.getBooleanInfo(v) },
          {
            name: 'Is Military Experienced',
            key: 'attributes.militaries',
            formatter: (v) => this.getArrBooleanInfo(v),
          },
        ],
        sections: {
          education: {
            name: 'Educational Information',
            id: 'educationInformation',
            data: [
              {
                name: 'High School',
                key: 'high_schools_info_data',
                checkField: 'attributes.attend',
                fields: [
                  { name: 'Name of High School', key: 'attributes.name' },
                  { name: 'Dates of Attendance (Start)', key: 'attributes.start_date_of_attendance' },
                  { name: 'Dates of Attendance (End)', key: 'attributes.end_date_of_attendance' },
                  { name: 'Graduated', key: 'attributes.graduated', formatter: (v) => this.getBooleanInfo(v) },
                  {
                    name: 'Year Graduated',
                    key: 'attributes.year_graduated',
                    visible: (v) => Boolean(this.get(v, 'attributes.graduated')),
                  },
                  { name: 'Degree', key: 'attributes.degree' },
                  { name: 'Concentration', key: 'attributes.concentration' },
                  { name: 'Honors or Awards', key: 'attributes.honors' },
                ],
              },
              {
                name: 'College',
                key: 'colleges_info_data',
                checkField: 'attributes.attend',
                fields: [
                  { name: 'Name of College', key: 'attributes.name' },
                  { name: 'Dates of Attendance (Start)', key: 'attributes.start_date_of_attendance' },
                  { name: 'Dates of Attendance (End)', key: 'attributes.end_date_of_attendance' },
                  { name: 'Graduated', key: 'attributes.graduated', formatter: (v) => this.getBooleanInfo(v) },
                  {
                    name: 'Year Graduated',
                    key: 'attributes.year_graduated',
                    visible: (v) => Boolean(this.get(v, 'attributes.graduated')),
                  },
                  { name: 'Degree', key: 'attributes.degree' },
                  { name: 'Concentration', key: 'attributes.concentration' },
                  { name: 'College Additional Concentration', key: 'attributes.additional_concentration' },
                  { name: 'Honors or Awards', key: 'attributes.honors' },
                ],
              },
              {
                name: 'Graduate School',
                key: 'graduate_schools_info_data',
                checkField: 'attributes.attend',
                fields: [
                  { name: 'Name of Graduate School', key: 'attributes.name' },
                  { name: 'Dates of Attendance (Start)', key: 'attributes.start_date_of_attendance' },
                  { name: 'Dates of Attendance (End)', key: 'attributes.end_date_of_attendance' },
                  { name: 'Graduated', key: 'attributes.graduated', formatter: (v) => this.getBooleanInfo(v) },
                  {
                    name: 'Year Graduated',
                    key: 'attributes.year_graduated',
                    visible: (v) => Boolean(this.get(v, 'attributes.graduated')),
                  },
                  { name: 'Degree', key: 'attributes.degree' },
                  { name: 'Concentration', key: 'attributes.concentration' },
                  { name: 'Honors or Awards', key: 'attributes.honors' },
                ],
              },
              {
                name: 'Professional Licenses',
                key: 'licenses_info_data',
                checkField: 'attributes.have',
                fields: [
                  {
                    name: 'Have any professional licenses',
                    key: 'attributes.have',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Year License obtained',
                    key: 'attributes.year',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Type of License',
                    key: 'attributes.license_type',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Date of Expiration of License',
                    key: 'attributes.date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
            ],
          },
          residential: {
            name: 'Residential Information',
            id: 'residentialInformation',
            data: [
              {
                name: 'Landlord',
                key: 'landlords_info_data',
                // checkField: 'attributes.have',
                fields: [
                  {
                    name: 'Do you currently have a Landlord ?',
                    key: 'attributes.have',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Landlord Name',
                    key: 'attributes.name',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Phone',
                    key: 'attributes.phone',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Email',
                    key: 'attributes.email',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Dates of tenancy (Start)',
                    key: 'attributes.tenancy_start_date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Dates of tenancy (End)',
                    key: 'attributes.tenancy_end_date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Address',
                    key: 'attributes.address',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
              {
                name: 'Prior Landlord',
                key: 'prior_landlords_info_data',
                // checkField: 'attributes.have',
                fields: [
                  {
                    name: 'Do you have a prior Landlord ?',
                    key: 'attributes.have',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Landlord Name',
                    key: 'attributes.name',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Phone',
                    key: 'attributes.phone',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Email',
                    key: 'attributes.email',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Dates of tenancy (Start)',
                    key: 'attributes.tenancy_start_date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Dates of tenancy (End)',
                    key: 'attributes.tenancy_end_date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Landlord Address',
                    key: 'attributes.address',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
            ],
          },
          employment: {
            name: 'Employment Information',
            id: 'employmentInformation',
            data: [
              {
                name: 'Currently employed',
                key: 'current_employments_info_data',
                checkField: 'attributes.have',
                fields: [
                  { name: 'Employer Name', key: 'attributes.name' },
                  { name: 'Employer Address', key: 'attributes.address' },
                  { name: 'Supervisor Name', key: 'attributes.supervisor_name' },
                  { name: 'Supervisor telephone number', key: 'attributes.supervisor_phone' },
                  { name: 'Supervisor e-mail address', key: 'attributes.supervisor_email' },
                  { name: 'Dates of employment (Start)', key: 'attributes.start_date' },
                  { name: 'Dates of employment (End)', key: 'attributes.end_date' },
                  { name: 'Job Title', key: 'attributes.job_title' },
                  { name: 'Responsibilities', key: 'attributes.responsibilities' },
                ],
              },
              {
                name: 'Previously employed',
                key: 'previous_employments_info_data',
                checkField: 'attributes.have',
                fields: [
                  { name: 'Employer Name', key: 'attributes.name' },
                  { name: 'Employer Address', key: 'attributes.address' },
                  { name: 'Supervisor Name', key: 'attributes.supervisor_name' },
                  { name: 'Supervisor telephone number', key: 'attributes.supervisor_phone' },
                  { name: 'Supervisor e-mail address', key: 'attributes.supervisor_email' },
                  { name: 'Dates of employment (Start)', key: 'attributes.start_date' },
                  { name: 'Dates of employment (End)', key: 'attributes.end_date' },
                  { name: 'Job Title', key: 'attributes.job_title' },
                  { name: 'Responsibilities', key: 'attributes.responsibilities' },
                  { name: 'Reason For Leaving', key: 'attributes.reason_for_leaving' },
                ],
              },
            ],
          },
          misc: {
            name: 'Miscellaneous Information',
            id: 'miscellaneousInformation',
            data: [
              {
                key: 'attributes',
                fields: [{ name: 'Legal Resident', key: 'legal_resident', formatter: (v) => this.getBooleanInfo(v) }],
                fields: [{ name: 'Diplomat', key: 'diplomat', formatter: (v) => this.getBooleanInfo(v) }],
              },
              {
                key: 'militaries_info_data',
                fields: [
                  {
                    name: 'Have Military Experience',
                    key: 'attributes.have',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Branch Of Service',
                    key: 'attributes.branch_of_service',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Start Year Of Service',
                    key: 'attributes.start_year_of_service',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'End Year Of Service',
                    key: 'attributes.end_year_of_service',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Type Of Discharge',
                    key: 'attributes.type_of_discharge',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
              {
                key: 'crimes_info_data',
                fields: [
                  {
                    name: 'Been convicted of a crime',
                    key: 'attributes.have',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Type Of Conviction',
                    key: 'attributes.type_of_conviction',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Type Of Conviction Of',
                    key: 'attributes.type_of_convicted_of',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
              {
                key: 'evicteds_info_data',
                fields: [
                  { name: 'Been Evicted', key: 'attributes.have', formatter: (v) => this.getBooleanInfo(v) },
                  {
                    name: 'Date of eviction',
                    key: 'attributes.date',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                  {
                    name: 'Explanation of evictions/lawsuits',
                    key: 'attributes.explanation',
                    visible: (v) => Boolean(this.get(v, 'attributes.have')),
                  },
                ],
              },
            ],
          },
        },
        generalHeaders: {
          assets: {
            name: 'Assets',
            data: {
              cashes: {
                name: 'Сash Assets',
                fields: [
                  { name: 'Account Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Amount Balance', key: 'attributes.amount', balance: true },
                ],
              },
              certificate_deposites: {
                name: 'Certificate of Deposits',
                fields: [
                  { name: 'Account Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Amount Balance', key: 'attributes.amount', balance: true },
                ],
              },
              securities: {
                name: 'Securities',
                fields: [
                  { name: 'Account Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Amount Balance', key: 'attributes.amount', balance: true },
                ],
              },
              real_estates: {
                name: 'Any Real Estate',
                fields: [
                  { name: 'Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Real Estate', key: 'index' },
                  { name: 'Percentage', key: 'attributes.percentage' },
                  { name: 'Type of Property', key: 'attributes.type_of_property' },
                  { name: 'Location', key: 'attributes.location' },
                  { name: 'Estimated Value', key: 'attributes.estimated_value', balance: true },
                  { name: 'Mortgage Balance', key: 'attributes.mortgage_balance', balance: true },
                ],
              },
              vehicles: {
                name: 'Any Vehicles',
                fields: [
                  { name: 'Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Vehicle', key: 'index' },
                  { name: 'Make', key: 'attributes.make' },
                  { name: 'Model', key: 'attributes.model' },
                  { name: 'Year', key: 'attributes.year' },
                  { name: 'Loan Balance', key: 'attributes.loan_balance', balance: true },
                  { name: 'Estimated Value', key: 'attributes.estimated_value', balance: true },
                ],
              },
              individual_retirement_accounts: {
                name: 'Any IRA, 401k or Other Retirement Account',
                fields: [
                  { name: 'Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Type', key: 'attributes.ira_type' },
                  { name: 'Balance', key: 'attributes.balance', balance: true },
                ],
              },
              life_insurances: {
                name: 'Any Life Insurance',
                fields: [
                  { name: 'Policy Holder', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Policy Name', key: 'attributes.name' },
                  { name: 'Policy Type', key: 'attributes.life_insurance_type' },
                  { name: 'Policy Benefit Amount', key: 'attributes.benefit_amount', balance: true },
                  { name: 'Policy Beneficiary', key: 'attributes.beneficiary' },
                ],
              },
              businesses: {
                name: 'Any Owned Businesses',
                fields: [
                  { name: 'Business Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Business Name', key: 'attributes.name' },
                  { name: 'Percentage Ownership Interest', key: 'attributes.percentage' },
                  { name: 'Date of Formation', key: 'attributes.date_of_formation' },
                  { name: 'Date of Termination', key: 'attributes.date_of_termination' },
                  { name: 'Estimated Value of Business', key: 'attributes.estimated_value', balance: true },
                  {
                    name: 'Estimated Amount of Liabilities',
                    key: 'attributes.estimated_amount_of_liabilities',
                    balance: true,
                  },
                  { name: 'Estimated Amount of Assets', key: 'attributes.estimated_amount_of_assets', balance: true },
                ],
              },
              properties: {
                name: 'Any Personal Property',
                fields: [
                  { name: 'Property Owner', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Item Description', key: 'attributes.description' },
                  { name: 'Estimated Value of Item', key: 'attributes.estimated_value', balance: true },
                ],
              },
            },
          },
          liabilities: {
            name: 'Liabilities',
            data: {
              installment_debts: {
                name: 'Installment Debt',
                fields: liabilitiesFileds,
              },
              credit_card_debts: {
                name: 'Any Credit Card Debts',
                fields: liabilitiesFileds,
              },
              mortgages: {
                name: 'Any Mortgage',
                fields: liabilitiesFileds,
              },
              automobiles: {
                name: 'Car Loan',
                fields: liabilitiesFileds,
              },
              other_liabilities: {
                name: 'Other',
                fields: liabilitiesFileds,
              },
            },
          },
          annual_income: {
            name: 'Income (Per Year)',
            data: {
              data: {
                name: '',
                fields: [
                  { name: 'Owner', key: 'admin_user_id', owner: true },
                  { name: 'Income', key: 'income', balance: true },
                  { name: 'Bonus', key: 'bonus', balance: true },
                  { name: '401(k) or Any Other Securities', key: 'securities_amount', balance: true },
                  { name: 'Social Security', key: 'social_security', balance: true },
                  { name: 'K-1 Income', key: 'k_income', balance: true },
                  { name: 'Dividends', key: 'dividents', balance: true },
                  { name: 'Investment Property or Properties', key: 'investment_properties', balance: true },
                  { name: 'Any Other Income', key: 'other_incomes', balance: true },
                ],
              },
            },
          },
          expenses: {
            name: 'Expenses (Per Month)',
            data: {
              vehicle_leases: {
                name: 'Vehicle Lease and Loan Payments',
                fields: expensesFields,
              },
              personal_loans: {
                name: 'Personal Loans',
                fields: expensesFields,
              },
              student_loans: {
                name: 'Student Loans',
                fields: expensesFields,
              },
              mortgage_expenses: {
                name: 'Mortgage(s)',
                fields: expensesFields,
              },
              alimonies: {
                name: 'Alimony',
                fields: expensesFields,
              },
              child_supports: {
                name: 'Child Support',
                fields: expensesFields,
              },
              other_expenses: {
                name: 'Other Monthly Expenses',
                fields: expensesFields,
              },
            },
          },
          others: {
            name: 'Other Financial Information',
            data: {
              outstanding_judgments: {
                name: 'Outstanding Judgments',
                fields: [
                  { name: 'Debtor', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Explanation of Judgment', key: 'attributes.explanation' },
                ],
              },
              personal_bankruptcies: {
                name: 'Personal Bankruptcy within last 7 years',
                fields: [
                  { name: 'Debtor', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Result', key: 'attributes.result' },
                  { name: 'Explanation', key: 'attributes.explanation' },
                ],
              },
              business_bankruptcies: {
                name: 'Business Bankruptcy within last 7 years',
                fields: [
                  { name: 'Debtor', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Result', key: 'attributes.result' },
                  { name: 'Explanation', key: 'attributes.explanation' },
                ],
              },
              property_forcloseds: {
                name: 'Owned property foreclosed on',
                fields: [
                  { name: 'Debtor', key: 'attributes.admin_user.data.id', owner: true },
                  { name: 'Address of Property', key: 'attributes.address' },
                  { name: 'Explanation', key: 'attributes.explanation' },
                ],
              },
            },
          },
          divider: true,
          general: {
            name: 'Intended Use for Apartment',
            data: {
              instruments: {
                name: 'Instrument',
                fields: [{ name: 'Name', key: 'attributes.name' }],
              },
              alterations: {
                name: 'Any Alterations to the Apartment',
                fields: [{ name: 'Alterations', key: 'attributes.description' }],
              },
              subtenants: {
                name: 'Subletting',
                fields: [{ name: "Proposed subtenant's name", key: 'attributes.name' }],
              },
              pets: {
                name: 'Pets',
                fields: [
                  { name: 'Type of Pet', key: 'attributes.type_of_pets' },
                  { name: 'Number of Pets', key: 'attributes.number' },
                  { name: 'Breed of Pets', key: 'attributes.breed' },
                  { name: 'Type of Future Pets', key: 'attributes.type_of_future_pets' },
                ],
              },
              occupants: {
                name: 'Occupants Living in This Apartment',
                fields: [
                  { name: 'Name', key: 'attributes.name' },
                  {
                    name: 'Criminal Convictions(s)',
                    key: 'attributes.criminal_conviction',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Type of Criminal Conviction',
                    key: 'attributes.type_of_criminal_conviction',
                    visible: (v) => Boolean(this.get(v, 'attributes.criminal_conviction')),
                  },
                  {
                    name: 'Diplomatic Status',
                    key: 'attributes.dimplomatic_status',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Defendant in Lawsuit(s)',
                    key: 'attributes.defendant_in_lawsuit',
                    formatter: (v) => this.getBooleanInfo(v),
                  },
                  {
                    name: 'Defendant in lawsuit(s) explanation',
                    key: 'attributes.explanation',
                    visible: (v) => Boolean(this.get(v, 'attributes.defendant_in_lawsuit')),
                  },
                ],
              },
            },
          },
        },
        docs: {
          general: {
            name: 'General documents',
            fields: [
              { name: 'Contract of Sale', key: 'contract_of_sale' },
              // { name: 'Loan Commitment Letter', key: 'loan_commitment_letter' },
              // { name: 'Loan Application', key: 'loan_application' },
            ],
          },
          applicants: {
            name: "Applicants's documents",
            fields: [
              { name: 'Landlord Reference Letter', key: 'attributes.landlord_reference_letter' },
              { name: 'Personal Reference Letter(First)', key: 'attributes.personal_reference_letters_first' },
              { name: 'Personal Reference Letter(Second)', key: 'attributes.personal_reference_letters_second' },
              { name: 'Account Statements(First)', key: 'attributes.account_statements_first' },
              { name: 'Account Statements(Second)', key: 'attributes.account_statements_second' },
              { name: 'Professional Reference Letter(First)', key: 'attributes.professional_reference_letter_first' },
              { name: 'Professional Reference Letter(Second)', key: 'attributes.professional_reference_letter_second' },
              { name: 'Loan Commitment', key: 'attributes.loan_commitment_letter' },
              { name: 'Loan Application', key: 'attributes.loan_application' },
              // { name: 'Employment Verification Letter', key: 'attributes.employment_verification_letter' },
              { name: 'Pay Stubs(First)', key: 'attributes.pay_stubs_first' },
              { name: 'Pay Stubs(Second)', key: 'attributes.pay_stubs_second' },
              { name: 'Tax Returns(First)', key: 'attributes.tax_returns_first' },
              { name: 'Tax Returns(Second)', key: 'attributes.tax_returns_second' },
              { name: 'W-2 or Equivalent(First)', key: 'attributes.w2_first' },
              { name: 'W-2 or Equivalent(Second)', key: 'attributes.w2_second' },
            ],
          },
        },
      };
    },
    isApplicationRejected() {
      return this.reason_form.status == 'Application Rejected';
    },
    isApplicationAutoRejected() {
      return this.reason_form.autoReject;
    },
  },
  methods: {
    ...mapActions({
      updateApplicationStatus: 'applications/update',
      createInterview: 'interview/create',
      getApplication: 'applications/show',
      getSupplemental: 'supplemental/index',
      getApplicantSupplementals: 'applicantSupplemental/index',
    }),
    showBtn(application) {
      return !application.status ||
        application.status == 'In Progress' ||
        application.status == 'Application Incomplete' ||
        (Number(application.days_to_left) <= 0 && application.rejected)
        ? true
        : false;
    },
    focusFileInput(ref) {
      this.$refs[ref].click();
    },
    async handleFileUploaded(e) {
      const file = e.target.files.item(0);
      if (file) {
        this.reason_form.rejection_document = file;
        this.file_url = URL.createObjectURL(file);
      }
    },

    initInterviewForm(data = {}) {
      this.interview_form = {
        application_id: data.application_id || '',
        date: data.date || '',
      };
      this.$v.$reset();
    },
    initReasonForm(data = {}) {
      this.reason_form = {
        id: data.id || '',
        status: data.status || '',
        reason: '',
        rejection_document: '',
        autoReject: data.autoReject,
      };
      this.$v.$reset();
    },
    async onReasonSubmit() {
      if (!this.$v.reason_form.$invalid) {
        this.submiting = true;

        let params = {
          application: {
            id: this.reason_form.id,
            status: this.reason_form.status,
            reason: this.reason_form.reason,
            rejection_document: this.reason_form.rejection_document,
          },
        };

        if (!this.isApplicationRejected && !this.isApplicationAutoRejected) {
          delete params.application.rejection_document;
        }

        if (this.isApplicationAutoRejected) {
          delete params.application.status;
          delete params.application.reason;
          params.application.rejected = true;
        }

        params = this.jsonToFormData(params);

        try {
          await this.updateApplicationStatus(params);

          await this.getApplication(this.reason_form.id);

          this.reason_modal = false;
        } catch (error) {
          console.error('error :>> ', error);
        }

        this.submiting = false;
      } else {
        this.$v.reason_form.$touch();
      }
    },
    async handleInterviewSubmit() {
      if (!this.$v.interview_form.$invalid) {
        this.submiting = true;

        const time = this.$moment(this.interview_form.date).toISOString();

        const params = this.jsonToFormData({
          interview_schedule: {
            application_id: this.interview_form.application_id,
            date: this.interview_form.date,
            time,
          },
        });

        try {
          await this.createInterview(params);

          await this.updateApplicationStatus(
            this.jsonToFormData({
              application: { id: this.interview_form.application_id, status: 'Interview Scheduled' },
            })
          );

          await this.getApplication(this.interview_form.application_id);

          this.interview_modal = false;
        } catch (error) {
          console.error('error :>> ', error);
        }

        this.submiting = false;
      } else {
        this.$v.interview_form.$touch();
      }
    },
    handleActionClick(action, item) {
      const status = action.value || action.text;
      if (action.autoReject) {
        this.initReasonForm({ id: item.id, autoReject: true });
        this.reason_modal = true;
        return;
      }
      if (this.isInterview(status)) {
        this.initInterviewForm({ application_id: item.id });
        this.interview_modal = true;
      } else if (this.isReason(status)) {
        this.initReasonForm({ id: item.id, status });
        this.reason_modal = true;
      } else {
        this.updateApplicationStatus(
          this.jsonToFormData({
            application: { id: item.id, status },
          })
        ).then((result) => {
          this.getApplication(item.id);
        });
      }
    },
    getApplicantWithIndex(id) {
      console.log('id', id);
      const index = this.applicantsList.findIndex((a) => a.id == id);
      if (index != -1) return { index: index + 1, data: this.applicantsList[index] };

      return {};
    },
    hasAnyField(key, item = null) {
      let result = false;

      let data = this.$_.cloneDeep(this.applicantsList);

      if (item) data = data.filter((a) => a.id == item.id);
      for (const applicant of data) {
        for (const header of this.headers.sections[key].data) {
          if (!header.checkField) return true;
          if (this.get(applicant, header.key + '.' + header.checkField)) return true;
        }
      }

      return result;
    },

    async getOtherInfos() {
      const fields = [
        'colleges',
        'high_schools',
        'graduate_schools',
        'licenses',
        'landlords',
        'prior_landlords',
        'current_employments',
        'previous_employments',
        'militaries',
        'crimes',
        'evicteds',
      ];

      for (const field of fields) {
        const items = this.get(this.applicationData, `${field}.data`);
        for (const item of items) {
          const userId = this.get(item, 'attributes.admin_user.data.id');
          const index = this.applicantsList.findIndex((a) => a.id == userId);
          if (index == -1) continue;

          const applicant = this.applicantsList[index];

          this.$set(applicant, `${field}_info_data`, item);

          this.applicantsList[index] = applicant;
        }
      }
    },

    async getAssets() {
      const fields = ['assets', 'liabilities', 'expenses', 'others', 'general', 'annual_income'];

      for (const field of fields) {
        for (const key in this[field]) {
          if (field == 'annual_income') {
            this[field][key] = this.applicantsList.map((a) => {
              return {
                admin_user_id: a.id,
                income: a.attributes.income,
                bonus: a.attributes.bonus,
                securities_amount: a.attributes.securities_amount,
                social_security: a.attributes.social_security,
                k_income: a.attributes.k_income,
                dividents: a.attributes.dividents,
                investment_properties: a.attributes.investment_properties,
                other_incomes: a.attributes.other_incomes,
              };
            });
          } else {
            this[field][key] = this.get(this.applicationData, `${key}.data`, []);
          }
        }
      }

      this.contactsList.map((el) => {
        let key = '';

        if (el.attributes.contact_type === 'Seller') key = 'sellerData';

        if (el.attributes.contact_type === 'Seller Attorney') key = 'attorneyData';

        if (el.attributes.contact_type === 'Seller Broker') key = 'sellerBrokerData';

        if (el.attributes.contact_type === 'Applicant Broker') key = 'applicantBrokerData';

        if (el.attributes.contact_type === 'Purchase Attorney') key = 'purchaseData';

        if (key != '') {
          this.contacts[key] = el.attributes;
          this.contacts[key].id = el.id;
        }
        return el;
      });
    },

    getBooleanInfo(value) {
      return value === null ? 'N/A' : value ? 'Yes' : 'No';
    },

    getArrBooleanInfo(value) {
      const arr = value.data || [];
      if (arr.length) {
        return this.get(arr[0], 'attributes.have', false) ? 'Yes' : 'No';
      } else {
        return 'N/A';
      }
    },

    async getApplicationData() {
      this.applicantsList = this.get(this.applicationData, 'applicants.data', []);
      await this.getAssets();
      this.$root.$emit('changeTitle', this.applicationData.id);
    },
  },
  async mounted() {
    this.loading = true;
    await this.$await(this.getApplicationData());
    await this.$await(this.getOtherInfos());
    // [TEMP]
    await this.$await(
      this.getSupplemental({
        where: {
          cooperative_id: this.applicationData?.cooperative?.data?.id,
        },
      })
    );

    await this.$await(
      this.getApplicantSupplementals({
        where: {
          application_id: this.applicationData.id,
        },
      })
    );

    for (const supplemental of this.supplementals) {
      for (const [index, applicant] of this.applicationData.applicants.data.entries()) {
        const doc = await this.applicantSupplementals.find((s) => {
          return s?.supplemental_form?.data?.id == supplemental.id && s.admin_user?.data?.id == applicant.id;
        });
        this.supplementalDocs.push({
          id: doc?.id || null,
          name: supplemental.name + ' - Applicant ' + Number(index + 1),
          file: doc?.file || '',
          admin_user_id: applicant.id,
        });
      }
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
